(function(window, undefined) {
    'use strict';

    var kontur = window.kontur;
    
    if (!kontur) {
        kontur = window.kontur = {};
    }

    var plugin = kontur.plugin;

    if (!plugin) {
        plugin = kontur.plugin = {};
    }

    var JSON = plugin.JSON || window.JSON;

    function extend(target, properties) {
        for (var prop in properties) {
            if (properties.hasOwnProperty((prop))) {
                target[prop] = properties[prop];
            }
        }
        return target;
    }
    
    var create = Object.create || function(proto) {
        function F() {}
        F.prototype = proto;
        return new F();
    };
    
    function implement(ctor, base, properties) {
        var proto = create(base.prototype);
        if (typeof(properties) === 'object') {
            extend(proto, properties);
        }
        proto.constructor = ctor;
        ctor.prototype = proto;
        return ctor;
    }

    function guid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0;
            var v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    function compareVersion(v1, v2) {
        v1 = v1 || '';
        v2 = v2 || '';
        if (v1 !== v2) {
            var items1 = v1.split('.');
            var items2 = v2.split('.');
            for (var i = 0, l = Math.min(items1.length, items2.length); i < l; i++) {
                var num1 = Number(items1[i]);
                var num2 = Number(items2[i]);
                if (isNaN(num1) || isNaN(num2)) {
                    throw new Error('invalid version format: ' + isNaN(num1) ? v1 : v2);
                }
                if (num1 !== num2) {
                    return num1 > num2 ? 1 : -1;
                }
            }
        }
        return 0;
    }

    function Observable() {
        var events = {};
        this.on = function(type, callback) {
            var callbacks = events[type];
            if (!callbacks) {
                callbacks = events[type] = [];
            }
            callbacks.push(callback);
        };
        this.off = function(type, callback) {
            var callbacks = events[type];
            if (callbacks) {
                for (var i = 0, len = callbacks.length; i < len; i++) {
                    if (callbacks[i] === callback || callback === undefined) {
                        callbacks.splice(i, 1);
                    }
                }
            }
        };
        this.trigger = function(type) {
            var args = [].slice.call(arguments, 1);
            var callbacks = events[type];
            if (callbacks) {
                for (var i = 0, len = callbacks.length; i < len; i++) {
                    if (callbacks[i] !== undefined) {
                        callbacks[i].apply(this, args);
                    }
                }
            }
        };
    }

    var EVENT_ERROR = 'error';
    var EVENT_SEND = 'send';
    var EVENT_RESPONSE = 'send-response';
    var EVENT_CONNECT = 'connect';
    var EVENT_CLOSE = 'close';
    
    Observable.call(plugin);
    var trigger = plugin.trigger;
    delete plugin.trigger;

    function PluginError(message) {
        this.message = message || 'unknown error';
    }
    
    plugin.Error = implement(PluginError, Error);
    
    function ConnectError() {}
    function CommandError() {}
    function CancelError() {}
    function SecurityError() {}
    
    plugin.CommandError = implement(CommandError, PluginError);
    plugin.ConnectError = implement(ConnectError, CommandError);
    plugin.CancelError = implement(CancelError, CommandError);
    plugin.SecurityError = implement(SecurityError, CommandError);
    
    var ACCESS_DENIED_ERROR = 1;
    var CANCEL_ERROR = 5;

    function BaseHost() {
        var sessionId = null;
        var commandId = 1;
        var connected = false;
    
        this.createCommand = function(request) {
            var command = extend({}, request);
            if (sessionId === null) {
                sessionId = guid();
            }
            command.sessionId = sessionId;
            command.commandId = commandId++;
            return command;
        };
    
        this.createCommandError = function(commandId, info) {
            if (info === undefined) {
                return info;
            }
            var error;
            if (info.type === 'plugin') {
                switch (info.code) {
                    case CANCEL_ERROR:
                        error = new CancelError();
                        break;
                    case ACCESS_DENIED_ERROR:
                        error = new SecurityError();
                        error.denied = true;
                        break;
                }
            } else if (info.type === 'connect') {
                error = new ConnectError();
            }
            error = error || new CommandError();
            extend(error, info);
            error.commandId = commandId;
            trigger(EVENT_ERROR, error);
            return error;
        };
    
        this.processResponse = function(response, callback) {
            var result = response.result;
            var commandId = response.commandId;
            var error = this.createCommandError(commandId, response.error);
            trigger(EVENT_RESPONSE, commandId, error, result);
            if (callback) {
                callback(error, result);
            }
        };
    
        this.connected = function(state) {
            if (state === undefined) {
                return connected;
            } else {
                connected = !!state;
                if (!connected) {
                    sessionId = null;
                    commandId = 1;
                }
                trigger(connected ? EVENT_CONNECT : EVENT_CLOSE, this.type, this.version);
            }
        };
    }

    function ActiveXHost() {
        var ax = null;
        var self = this;
    
        BaseHost.call(this);
    
        this.type = 'active-x';
        this.version = '1.0';
    
        function garbageCollect() {
            if ('CollectGarbage' in window) {
                try {
                    window.CollectGarbage();
                } catch (e) { }
            }
        }
    
        function createPlugin(version) {
            var className = 'KonturPlugin';
            if (version) {
                className += '.' + version;
            }
            var object = null;
            try {
                object = new window.ActiveXObject(className);
                if (!version) {
                    version = object.Version;
                    var installedVersion = object.InstalledVersion;
                    if (installedVersion && compareVersion(installedVersion, version) > 0) {
                        try {
                            object = createPlugin(installedVersion);
                        } catch (err) {
                        }
                        garbageCollect();
                    }
                }
                return object;
            } catch (err) {
                object = null;
                garbageCollect();
                throw err;
            }
        }
    
        this.send = function(request, callback) {
            var command = this.createCommand(request);
            try {
                if (ax === null) {
                    ax = createPlugin();
                    this.connected(true);
                }
                var requestString = JSON.stringify(command);
                trigger(EVENT_SEND, command);
                ax.SendCommand(requestString, function(responseString) {
                    var response = JSON.parse(responseString);
                    self.processResponse(response, callback);
                });
            } catch (err) {
                this.processResponse({
                    commandId: command.commandId,
                    error: {
                        type: 'connect',
                        message: err.message ? err.message : err
                    }
                }, callback);
            }
            return command.commandId;
        };
    
        this.close = function() {
            if (ax === null) {
                return;
            }
            try {
                this.send({type: 'close'}, function() {
                    garbageCollect();
                });
            } catch (err) {
            }
            ax = null;
            self.connected(false);
            garbageCollect();
        };
    }

    function ExtensionHost() {
        var self = this;
        var callbacks = {};
        var CONNECT_TIMEOUT = 2000;
        var EXTENDED_CONNECT_TIMEOUT = 10000;
        var REQUEST_TYPE = 'kontur-toolbox-request';
        var RESPONSE_TYPE = 'kontur-toolbox-response';
        var INSTALLATION_FLAG_ATTRIBUTE = 'kontur-toolbox-installed';
    
        BaseHost.call(this);
    
        this.type = 'extension';
        this.extensionType = null;
    
        function checkExtensionMetaTag() {
            try {
                if (!!window.document && !!window.document.head) {
                    var meta = window.document.head.getElementsByTagName('meta');
                    for (var i = 0; i < meta.length; ++i) {
                        if (meta[i].hasAttribute(INSTALLATION_FLAG_ATTRIBUTE)) {
                            return true;
                        }
                    }
                }
            } catch (err) {
            }
            return false;
        }
    
        function notify(response) {
            var id = response.commandId;
            if (id !== undefined) {
                var callback = callbacks[id];
                delete callbacks[id];
                try {
                    self.processResponse(response, callback);
                } catch (err) {
                    trigger(EVENT_ERROR, err);
                }
            } else {
                for (id in callbacks) {
                    response.commandId = parseInt(id, 10);
                    notify(response);
                }
            }
        }
    
        function send(command, callback) {
            var id = command.commandId;
            try {
                trigger(EVENT_SEND, command);
                callbacks[id] = callback;
                window.postMessage({type: REQUEST_TYPE, request: command}, '*');
                return true;
            } catch (err) {
                delete callbacks[id];
                self.processResponse({
                    commandId: id,
                    error: {
                        type: 'connect',
                        message: err.message ? err.message : err
                    }
                }, callback);
                return false;
            }
        }
    
        function sendack(command, callback) {
            var timer = null;
            var aborted = false;
            var connectTimeout = checkExtensionMetaTag() ? EXTENDED_CONNECT_TIMEOUT : CONNECT_TIMEOUT;
            var commandSent = send(command, function(err, result) {
                if (timer !== null && !aborted) {
                    window.clearTimeout(timer);
                }
                callback(err, result);
            });
            if (commandSent) {
                timer = window.setTimeout(function() {
                    aborted = true;
                    notify({
                        commandId: command.commandId,
                        error: {
                            type: 'connect', 
                            message: 'extension is not installed'    
                        }
                    });
                }, connectTimeout);
            }
        }
    
        this.send = function(request, callback) {
            var connectCommand;
            if (!this.connected()) {
                connectCommand = this.createCommand({type: 'extension.info'});
            }
            var command = this.createCommand(request);
            if (connectCommand) {
                sendack(connectCommand, function(err, result) {
                    if (err) {
                        callback(err);
                    } else {
                        self.version = result.version;
                        self.extensionType = result.type || (window.InstallTrigger ? 'firefox' : 'chrome');
                        self.connected(true);
                        send(command, callback);
                    }
                });
            } else {
                send(command, callback);
            }
            return command.commandId;
        };
    
        this.close = function() {
            if (this.connected()) {
                var closeId = this.send({type: 'extension.close'});
                delete callbacks[closeId];
                this.connected(false);
                var response = {
                    error: {type: 'connect', message: 'plugin is closed'}
                };
                notify(response);
            }
        };
    
        window.addEventListener('message', function(event) {
            if (!!event.data && event.data.type === RESPONSE_TYPE) {
                notify(event.data.response);
            }
        }, false);
    }

    plugin.version = '3.10.8.308';
    
    var host = 'ActiveXObject' in window ? new ActiveXHost() : new ExtensionHost();
    
    plugin.send = function(command, callback) {
        return host.send(command, callback);
    };
    
    plugin.cancel = function(id, callback) {
        var cancelCommand = {
            type: 'cancel',
            target: id
        };
        return host.send(cancelCommand, callback);
    };
    
    plugin.progress = function(id, callback) {
        var progressCommand = {
            type: 'progress',
            target: id
        };
        return host.send(progressCommand, callback);
    };
    
    plugin.close = function() {
        host.close();
    };

    function CheckVersionError(target, minVersion) {
        this.message = 'Version of the ' + target + ' must be grater than ' + minVersion;
        this.target = target;
        this.minVersion = minVersion;
    }
    
    plugin.CheckVersionError = implement(CheckVersionError, PluginError);
    
    function checkVersion(minVersion, info) {
        if (minVersion) {
            if (typeof(minVersion) === 'string') {
                minVersion = {
                    plugin: minVersion
                };
            }
            if (info.hasExtension && 
                minVersion.extension && 
                compareVersion(info.extensionVersion, minVersion.extension[info.extensionType]) < 0) {
                info.error = new CheckVersionError('extension', minVersion.extension[info.extensionType]);
                return false;
            }
            if (info.hasPlugin &&
                minVersion.plugin && 
                compareVersion(info.pluginVersion, minVersion.plugin) < 0) {
                info.error = new CheckVersionError('plugin', minVersion.plugin);
                return false;
            }
        }
        return true;
    }
    
    plugin.check = function(minVersion, callback) {
        if (arguments.length < 2) {
            if (typeof(minVersion) === 'function') {
                callback = minVersion;
                minVersion = undefined;
            }
        }
        host.send({type: 'plugin.info'}, function(error, result) {
            var info = {
                ok: false,
                type: host.type
            };
            try {
                if (host.type === 'extension') {
                    info.hasExtension = !!host.version;
                    if (info.hasExtension) {
                        info.extensionType = host.extensionType;
                        info.extensionVersion = host.version;
                    }
                }
                info.hasPlugin = !!result;
                if (info.hasPlugin) {
                    extend(info, result);
                }
                if (error) {
                    info.error = error;
                }
                checkVersion(minVersion, info);
                info.ok = !info.error;
            } catch (ex) {
                info.error = ex;
            }
            if (callback) {
                callback(info);
            }
        });
    };
    
    plugin.checkAddon = function(name, minVersion, callback) {
        if (arguments.length < 3 && typeof(minVersion) === 'function') {
            callback = minVersion;
            minVersion = undefined;
        }
        host.send({type: 'addon.' + name + '.version'}, function(error, result) {
            var info = {
                installed: !!result
            };
            if (error) {
                info.error = error;
            } else {
                info.version = result;
                if (minVersion !== undefined && compareVersion(result, minVersion) < 0) {
                    info.error = new CheckVersionError('addon', minVersion);
                }
            }
            info.ok = !info.error;
            if (callback) {
                callback(info);
            }
        });
    };

    plugin.trace = (function() {
        var console = window.console;
    
        function onerror(err) {
            if (console) {
                console.error('kt:err', err);
            }
        }
    
        function onsend(command) {
            if (console) {
                var id = command.commandId;
                console.info('kt:req:' + id, command);
            }
        }
    
        function onresponse(id, err, result) {
            if (console) {
                var type = 'kt:res:' + id;
                if (err) {
                    console.error(type, err);
                } else {
                    console.info(type, result);
                }
            }
        }
    
        var on = plugin.on;
        var off = plugin.off;
    
        return function trace(enabled) {
            off(EVENT_ERROR, onerror);
            off(EVENT_SEND, onsend);
            off(EVENT_RESPONSE, onresponse);
            if (enabled === true || enabled === 'info') {
                on(EVENT_SEND, onsend);
                on(EVENT_RESPONSE, onresponse);
                on(EVENT_ERROR, onerror);
            } else if (enabled === 'error') {
                on(EVENT_ERROR, onerror);
            }
        };
    }());
    
    plugin.trace('error');
})(this);